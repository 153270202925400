/**
 * Design system variables
 */

// Accent
$ecart-accent: #001cbc;
$ecart-accent-100: #D3C6F4;
$ecart-accent-200: #B6A0EE;
$ecart-accent-300: #9879e8;
$ecart-accent-400: #5937D6;
$ecart-accent-500: #482FCD;
$ecart-accent-800: #3629C7;

// Primary
$ecart-primary: #68AE34;
$ecart-primary-100: #E1EFD6; 
$ecart-primary-200: #C3DFAE; 
$ecart-primary-300: #A4CE85; 
$ecart-primary-400: #86BE5D; 
$ecart-primary-600: #4E8327; 
$ecart-primary-700: #34571A; 

// Secondary
$ecart-secondary: #000000;
$ecart-secondary-100: #e9e9e9;
$ecart-secondary-200: #d9d9d9;
$ecart-secondary-300: #c4c4c4;
$ecart-secondary-400: #9d9d9d;
$ecart-secondary-500: #7B7B7B;
$ecart-secondary-600: #555555;

// General
$general-background: #edf2f9;
$general-black: #2f2f33;
$general-white: #fafbfb;
$general-success: #189771;
$general-error: #F44336;
$general-info: #2196F3;
$general-warning: #FF9800;
$general-pending: #fbbf24;

// Elevations
$elevation-100: 0px 4px 29px 0px #c9d8ed4d;
$elevation-200: 0px 20px 29px 0px rgba(201, 216, 237, 0.30);
$elevation-300: 0px 25px 29px 0px rgba(201, 216, 237, 0.50);
$elevation-400: 0px 30px 29px 0px rgba(201, 216, 237, 0.80);
$elevation-500: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);

//Grid Breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1280px,
    xxl: 1660px,
    xxxl: 1900px,
);

// Text Colors
$gray-100: #ebeef4;
$gray-200: #dae0ec;
$gray-300: #c9d2e3;
$gray-400: #a8b6d1;
$gray-500: #869ac0;
$gray-600: #657eae;
$gray-700: #4d6593;
$gray-800: #3c4e71;
$gray-900: #212837;
$blue: #1f6bff !default;
$blue-100: #e8f2fa !default;
$blue-200: #d1e6f8 !default;
$blue-400: #6598c2 !default;
$blue-700: #15669c !default;
$blue-800: #08436a !default;
$blue-900: #022b47 !default;
$indigo: #640df3 !default;
$purple: #5b2e91 !default;
$pink: #ff2d55 !default;
$red: #ff3b30 !default;
$orange: #ff9500 !default;
$yellow: #fbbf24 !default;
$green: #0cb884 !default;
$teal: #0cd096 !default;
$cyan: #30beff !default;
$white: #ffffff !default;
$black: #000000 !default;
$dark: $gray-900 !default;
$warning: #ff9800 !default;
$primary: $blue !default;
$text-muted: $gray-500 !default;
$orange-e1: #ff6b12 !default;
$orange-e2: #ff871e !default;
$orange-e3: #d44300 !default;
$orange-e4: #ff5112 !default;
$danger: $red;
$dashboard: $teal;

// scss-docs-start theme-color-variables
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;

$border-radius: 0.4rem !default;
$border-radius-sm: 0.3rem !default;
$border-radius-lg: 0.7rem !default;
$border-radius-pill: 50rem !default;

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
) !default;