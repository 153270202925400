
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.sidebar {
    position: relative;
    &__content {
        background-color: $white;
        box-shadow: 0 0 12px rgba(#000000, 0.06);
        height: calc(100vh - 60px);
        left: 0;
        position: fixed;
        top: 60px;
        transition: 500ms all;
        width: 100%;
        max-width: 70px;
        z-index: 1029;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .custom-scrollbar {
            overflow: hidden;
            transition: overflow 0.3s ease;
            height: 100%;
            scrollbar-width: thin;

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: #CACFD9;
                opacity: .45;
                border-radius: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &:hover {
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
        
        @media (max-width: 992px) {
            height: 100vh;
            left: -300px;
            top: 0;
            z-index: 1031;
        }
    
        &--open {
            max-width: 250px;
            align-items: normal;

            @media (max-width: 992px) {
                left: 0px;
            }
        }
    }

    &__scrollbar {
        overflow-y: visible !important;
        overflow-x: visible !important;
    }

    &__brand {
        align-items: center;
        display: flex;
        height: 90px;
        justify-content: center;
        width: 90%;
    }

    &__brand-logo {
        width: 150px;
    }

    &__nav-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    &__nav {
        padding: 16px 10px;
        list-style: none;

        &--other-links {
            border-top: 1px solid $ecart-secondary-100;
        }
    }

    &__nav-item {
        width: 100%;
        position: relative;
    }

    &__nav-link {
        background: transparent;
        border: none;
        color: $general-black;
        opacity: 1;
        display: block;
        transition: 200ms all;
        transition-delay: 150ms;
        transition-property: opacity;
        font-weight: 500;
        padding: 6px 6px;
        text-align: left;
        text-decoration: none;
        width: 100%;
        size: 12px;
        margin-top: 5px;
        border-radius: 5px;
        cursor: pointer;

        &--close {
            display: none;
            opacity: 0;
        }
        
        &--active {
            background-color: $ecart-primary-100;

            span {
                color: $ecart-primary;
            }
        }

        &:hover {
            background-color: $ecart-primary-100;
            color: $general-black;
        }
    }

    &__nav-list {
        width: 100%;
        padding-left: 20px;
        list-style: none;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;

        &--open {
            max-height: 500px;
        }
    }
    
    &__nav-sub-link {
        background: transparent;
        border: none;
        border-left: 3px solid transparent;
        color: $general-black;
        opacity: 1;
        display: block;
        transition: 200ms all;
        transition-delay: 150ms;
        transition-property: opacity;
        font-weight: 400;
        text-decoration: none;
        width: 100%;
        padding: 4px 10px;
        margin-top: 10px;
        border-radius: 5px;
        cursor: pointer;

        &--close {
            display: none;
            opacity: 0;
        }
        
        &--active {
            background-color: $ecart-primary-100;
            span {
                color: $ecart-primary;
            }
        }

        &:hover {
            background-color: $ecart-primary-100;
            color: $general-black;
        }
    }

    &__nav-icon {
        margin-right: 10px;
        margin-left: 10px;
        width: 16px;
        height: 16px;
        z-index: 1;
    }

    &__nav-subtitle {
        font-size: 16px;
        font-weight: 500;
        padding: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-bottom: 1px solid $ecart-secondary-200;
    }

    &__nav-tittle {
        font-size: 14px;
        line-height: 14px;
        padding-left: 10px;
    }

    &__nav-arrow {
        width: 12px;
        height: 12px;
        padding-top: 5px;
        float: right;
        
    }

    &__tooltip {
        left: 100%;
        top: 0;
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        padding: 10px;
        border-radius: 4px;
        z-index: 10000;
        position: absolute;
        width: 200px;
        white-space: nowrap;
    }

    &__tooltip-link {
        display: block;
        padding: 5px 10px;
        color: #333;
        text-decoration: none;
    }

    &__tooltip-link:hover {
        background-color: #f0f0f0;
    }

    @media (max-width: 992px) {
        &__backdrop {
            background-color:rgba(33, 40, 55, 0.5);
            display: none;
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: 1030;

            &--open {
                display: block;
            }
        }
    }

    &__button-collapse {
        border: none;
        width: 44px;
        height: 44px;
        background: $general-white;
        right: -22px;
        position: absolute;
        bottom: 100px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: 500ms all;
        transform: rotate(180deg);

        &--open {
            transform: rotate(0deg);
        }
    }
}
