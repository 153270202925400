
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.header {
    align-items: center;
    border-bottom: 1px solid $ecart-secondary-200;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    background: $white;
    height: 60px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1030;
    transition: 300ms all;
    width: 100vw;

    @media(max-width: 992px) {
        width: 100vw;
    }

    &--open {
        width: 100vw;
    
        @media (max-width: 768px) {
            width: 100vw;
            padding-left: 0;
            padding-right: 1rem;
        }
    }

    &__right-container {
        align-items: center;
        display: flex;
        margin-left: auto;
        position: relative;
    }

    &__left-container {
        align-items: center;
        display: flex;
        padding: 25px;
    }

    &__toggler {
        background-color: transparent;
        border: none;
        display: none;
        font-size: 1.3rem;

        @media (max-width: 768px) {
            display: block;
        }
    }

    &__title {
        color: $ecart-primary;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 0;
        text-transform: capitalize;
    }

    &__navbar {
        padding-left: 45px;
        padding-right: 45px;
    }

    &__brand {
        width: 59px;
        height: 59px;
        display: none;
        @media(max-width: 992px) {
            width: auto;
            display: block;
        }
    }

    &__brand-logo {
        align-items: center;
        justify-content: center;
        display: block;
        padding: 0px 25px;
        width: calc(250px - 6rem);

        @media(max-width: 992px) {
            width: auto;
            display: none;
        }
    }

    &__notification-button {
        align-items: center;
        border: none;
        display: flex;
        width: 32px;
        height: 59px;
        font-size: 18px;
        justify-content: center;
        position: relative;
        background: $general-white;

        &:hover {
            background: $ecart-secondary-100;
        }
    }

    &__sidebar-button {
        align-items: center;
        border: none;
        width: 40px;
        height: 59px;
        font-size: 22px;
        justify-content: center;
        position: relative;
        background: $general-white;
        display: none;
        &:hover {
            background: $ecart-secondary-100;
        }
        @media(max-width: 992px) {
            width: auto;
            display: block;
        }
    }

    &__country-selector {
        margin-left: 5px;
        margin-right: 5px;
        width: 150px;

        @media(max-width: 992px) {
            width: auto;
            display: none;
        }
    }

    &__account-selector {
        width: 250px;
        display: block;

        @media(max-width: 1025px) {
            width: auto;
            display: none;
        }
    }

    &__profile-dropdown {
        display: flex;
        height: 59px;
        gap: 4px;
        border: none;
        padding: 0 16px 0px 16px;
        background: $general-white;
        border-left: 1px solid $ecart-secondary-200;
        border-right: 1px solid $ecart-secondary-200;
        align-items: center;
        cursor: pointer;
        &::after {
            display: none;
        }
        &:hover {
            background: $ecart-secondary-100;
        }
    }

    &__avatar {
        margin-right: 4px;
    }

    &__options {
        color: #495057;
    }

    &__profile-name {
        color: $general-black;
        size: 14px;
        line-height: 21px;
        font-weight: 600;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: normal;
    }

    &__profile-email {
        color: rgba($general-black, 0.7);
        size: 14px;
        line-height: 21px;
        font-weight: 500;
        text-align: left;
    }

    &__profile-name,
    &__profile-email {
        @media (max-width: 576px) {
            width: 80px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .profile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .profile-menu {
        background: white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        z-index: 1040;
        width: 250px;
        margin-top: 60px;
        margin-left: auto;
        position: relative;

        @media (max-width: 992px) {
            margin-left: calc(100% - 310px);
        }
    }

    .profile-menu ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .profile-menu li {
        padding: 10px 20px;
        cursor: pointer;
    }

    .profile-menu__options {
        padding: 10px 20px;
        cursor: pointer;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        text-align: left;

        &:hover {
            background: $ecart-secondary-100;
        }
    }
}

