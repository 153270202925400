
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.ui-input {
    &__prepend {
        background-color: $ecart-primary;
        border-radius: 8px 0px 0px 8px;
        color: #ffffff;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        padding: 4.5px 8px;
    }

    &__append {
        background-color: $ecart-primary;
        border-radius: 0px 8px 8px 0px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4.5px 8px;
    }

    &__container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        height: 40px;
        position: relative;
        width: 100%;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        &--has-append {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &__label {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(50% - 7px);

        transition: 200ms all;
    }

    &__clear-container {
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    &__clear-button {
        align-items: center;
        border: none;
        border-radius: 100%;
        background-color: transparent;
        color: $ecart-secondary-500;
        display: flex;
        flex-shrink: 0;
        height: 22px;
        justify-content: center;
        margin-right: 2px;
        padding: 2px;
        transition: 200ms all;
        width: 22px;

        &:hover {
            background-color: $ecart-secondary-100;
        }
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        padding: 4.5px 8px;
        padding-top: 15px;
        width: 100%;
        padding-right: 2.5rem; 

        &::placeholder {
            opacity: 0;
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:focus {
            background-color: transparent;

            &::placeholder {
                transition: opacity 200ms ease-in;
                opacity: 1;
            }
        }

        &:disabled {
            background-color: $ecart-secondary-100;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}

.ui-input__input:focus ~ .ui-input__label,
.ui-input__input:not(:placeholder-shown) ~ .ui-input__label {
    color: $general-black;
    font-size: 12px;
    top: 4px;
}

.ui-input__input:focus,
.ui-input__input:not(:placeholder-shown) {
    bottom: 4px;
    top: auto;
}

.ui-input__input::-webkit-search-cancel-button {
    display: block;
    -webkit-appearance: none;
    height: 2em;
    width: 2em;
    background: url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/></svg>') 50% 50% no-repeat;
    background-size: 1.1em;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    padding: 0;
    border: none;
    opacity: 0.5;
    transition: opacity 0.3s;    
}
