
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: $gray-600;
    border-radius: 50%;
    color: white;
    font-weight: 400;
    font-size: 1.8rem;
    &.xs {
        width: 30px;
        height: 30px;
        font-size: 1.1rem;
        padding: 0;
    }
    &.sm {
        width: 50px;
        height: 50px;
        font-size: 1.3rem;
    }
    &.lg {
        width: 70px;
        height: 70px;
        font-size: 2rem;
    }
    &.xl {
        width: 80px;
        height: 80px;
        font-size: 2.3rem;
    }
    &.xxl {
        width: 100px;
        height: 100px;
        font-size: 2.3rem;
    }
}

.profile-picture-container {
    width: 60px;
    height: 60px;
    &.xs {
        width: 30px;
        height: 30px;
        font-size: 1.1rem;
        padding: 0;
    }
    &.sm {
        width: 50px;
        height: 50px;
        font-size: 1.3rem;
        padding: 0;
    }
    &.lg {
        width: 70px;
        height: 70px;
        font-size: 2rem;
        padding: 0;
    }
}

.profile-picture {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
