
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.ui-button {
    align-items: center;
    border: 1px solid #EEEEEE;
    color: #202225;
    cursor: pointer;
    display: inline-flex;
    font-weight: 500;
    justify-content: center;
    line-height: 1;
    padding: 12px;
    text-align: center;
    text-decoration: none;    
    transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out;
    
    &:hover {
        text-decoration: none;
    }
    
    // Variants
    &--danger {
        background-color: #F44336;
        color: #fff;

        &:disabled, &:hover {
            background-color: #F45346;
            border-color: #F45346;
        }

        &:focus {
            border-color: #F45040;
        }
    }

    &--primary {
        background-color: $ecart-primary;
        border-color: $ecart-primary;
        color: #ffffff;

        &:hover {
            background-color: $ecart-primary-300;
            border-color: $ecart-primary-300;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-primary-100;
            border-color: $ecart-primary-100;
            color: $ecart-primary-300;
        }

        &:focus {
            border-color: $ecart-primary-100;
        }
    }

    &--secondary {
        background-color: $ecart-secondary-200;
        border-color: $ecart-secondary-200;
        color: $ecart-secondary;

        &:hover {
            background-color: $ecart-secondary-300;
            border-color: $ecart-secondary-300;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-secondary-100;
            border-color: $ecart-secondary-100;
            color: $ecart-secondary-300;
        }

        &:focus {
            border-color: $ecart-secondary-200;
        }
    }

    &--light {
        background-color: $ecart-secondary-100;
        border-color: $ecart-secondary-100;
        color: $ecart-secondary;

        &:hover {
            background-color: $ecart-secondary-200;
            border-color: $ecart-secondary-200;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-secondary-100;
            border-color: $ecart-secondary-100;
            color: $ecart-secondary-300;
        }

        &:focus {
            border-color: $ecart-secondary-100;
        }
    }

    &--outline-secondary {
        background-color: transparent;
        border-color: $ecart-secondary-200;
        color: $ecart-secondary-600;

        &:hover {
            background-color: $ecart-secondary-200;
            border-color: $ecart-secondary-200;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-secondary-100;
            border-color: $ecart-secondary-100;
            color: $ecart-secondary-300;
        }

        &:focus {
            border-color: $ecart-secondary-200;
        }
    }

    &--outline-accent {
        background-color: transparent;
        border-color: $ecart-accent;
        color: $ecart-accent;

        &:hover {
            background-color: $ecart-accent;
            border-color: $ecart-accent;
            color: $white;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-accent-100;
            border-color: $ecart-accent-100;
            color: $ecart-accent-300;
        }

        &:focus {
            border-color: $ecart-accent;
        }
    }

    &--outline-primary {
        background-color: transparent;
        border-color: $ecart-primary;
        color: $ecart-primary;

        &:hover {
            background-color: $ecart-primary;
            border-color: $ecart-primary;
            color: $white;
        }

        &:disabled {
            cursor: initial;
            background-color: $ecart-primary-100;
            border-color: $ecart-primary-100;
            color: $ecart-primary-300;
        }

        &:focus {
            border-color: $ecart-primary;
        }
    }

    &--link {
        color: $ecart-accent;
        background-color: transparent;
        border-color: transparent;
        padding: 0;

        &:hover {
            color: rgba($ecart-accent, 0.7);
        }
    }

    // Border radius variants
    &--rounded-sm {
        border-radius: 4px;
    }

    &--rounded-md {
        border-radius: 8px;
    }

    &__icon {
        font-size: 12px;
        margin-right: 2px;

        &--only-icon {
            font-size: 16px;
            margin-right: 0;
        }
    }

    &__loader {
        font-size: 14px;
        margin-right: 2px;
    }
}
