
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.ui-select {
    position: relative;
    min-width: 120px;
    max-width: 100%;

    &__container {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        height: 40px;
        position: relative;
        padding: 0;
        width: 100%;

        &::after {
            color: $ecart-accent;
            content: '\f078';
            font-family: 'Font Awesome 5 Free', sans-serif;
            font-size: 16px;
            font-weight: 900;
            line-height: 1;
            position: absolute;
            right: 8px;
            top: calc(50% - 8px);
        }

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &:disabled {
            background-color: $ecart-secondary-100;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }

        &--has-prepend {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &__label {
        font-size: 14px;
        font-weight: 400;
        color: $ecart-secondary-400;
        line-height: 1;
        position: absolute;
        left: 8px;
        top: calc(50% - 7px);
        transition: 200ms all;

        &--focus {
            color: $general-black;
            font-size: 12px;
            top: 4px;
        }

        &--has-image {
            left: 33.5px;
        }
    }

    &__value {
        color: $general-black;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        overflow: hidden;
        padding: 17px 32px 4.5px 8px;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100%);

        &--has-image {
            padding-left: 0;
        }
    }

    &__search-input {
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        padding: 4.5px 8px;
        width: 100%;

        &:focus {
            border-color: #bddb91;
            box-shadow: 0 0 0 0.2rem rgba(#81b23a, 0.25);
        }
    }

    &__option {
        display: flex;
        padding: 0.5rem 1rem;
        color: $general-black;
        background: none;
        width: 100%;
        text-align: left;
        border: none;

        &:hover, &--selected {
            background-color: #f2f7eb !important;
            color: #81b23a !important;
        }

    }

    &__option-image-container {
        border: 1px solid $ecart-secondary-200;
        border-radius: 6px;
        flex-shrink: 0;
        height: 24px;
        margin-right: 5px;
        overflow: hidden;
        width: 24px;

        margin-left: 4.5px;
    }

    &__option-image {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    &__option-label {
        flex-shrink: 0;
    }

    &__list-container {
        position: absolute;
        z-index: 99;
        width: 100%;
        left: 0;
    }

    &__list {
        overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        max-height: 250px;
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}

.loading {
    position: absolute;
    right: 30px;
    top: -19.5px;
}

.badge {
    font-size: 12px;
    margin: 0.2rem;
    padding: 0.4rem 0.55rem;

    & em {
        margin-left: 0.2rem;
    }
}
