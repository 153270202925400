
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.ui-link {
    text-decoration: none;
    transition: 500ms all;
    
    &--primary {
        color: $ecart-accent;

        &:hover {
            color: rgba($ecart-accent, 0.7);
        }
    }

    &--secondary {
        color: $ecart-secondary-600;

        &:hover {
            color: rgba($ecart-secondary-600, 0.7);
        }
    }

    &--global {
        color: $ecart-primary;

        &:hover {
            color: rgba($ecart-primary, 0.7);
        }
    }

    &--regular {
        font-weight: 400;
    }

    &--medium {
        font-weight: 500;
    }

    &--bold {
        font-weight: 700;
    }

    &--underline {
        text-decoration: underline;
    }
}
