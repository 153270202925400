
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.loading-cell {
    padding: 50px 15px;
    text-align: center;
}

.table-stacked-lg {
    @include media-breakpoint-down(lg) {
        thead {
            display: none;
        }
        tbody {
            border-top: 0;
        }
        tr:not(:first-child) td:first-child {
            border-top: 1px solid $gray-200;
        }
        tr {
            display: flex;
            flex-direction: column;
            td {
                display: flex;
                border-bottom: 0;
            }
            td > div {
                width: 60%;
                text-align: right;
            }
            td::before {
                content: attr(data-label);
                display: block;
                width: 40%;
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
}

.col-full {
    padding: 0;
}
