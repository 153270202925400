
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.dashboard {
    width: 100%;

    &__content {
        float: right;
        margin-top: 65px;
        min-height: calc(100vh - 65px);
        width: calc(100% - 70px);
        box-sizing: border-box;
        transition: 300ms all;
        padding: 25px 30px;

        @media (max-width: 992px) {
            padding: 20px 15px;
            width: 100%;
        }

        @media (max-width: 768px) {
            width: 100%;
            padding: 20px 15px;
        }

        &--open {
            width: calc(100% - 250px);

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
.active-user-container {
    display: flex;
    align-items: center;

    .delete-button {
        margin-left: 10px;
        cursor: pointer;
    }
}
.chargebacks-list, .activitys-list {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-family: 'Font Awesome 5 Free', sans-serif;
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
