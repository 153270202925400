
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.ui-modal {

    &__modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
    }

    &__close-button {
        margin-left: auto;
        border: none;
        background: none;
    }

    &__modal-footer {
        border-top: none;
    }
}
