
                    @import "@/assets/scss/_variables.scss";
                    @import "~bootstrap/scss/mixins/_breakpoints.scss";
                

.error-state {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;

    @media(max-width: 576px) {
        background-color: #ffffff;
        box-shadow: none;
    }

    &__card {
        margin-top: 100px;
        float: center;
        width: calc(100% - 10px);
        box-sizing: border-box;
        transition: 300ms all;
        padding: 25px 30px;

        @media (max-width: 998px) {
            padding: 20px 15px;
        }

        @media (max-width: 768px) {
            width: 100%;
            padding: 20px 15px;
        }

        &--open {
            width: calc(100% - 250px);

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        height: 50%;
        width: 10%;
        object-fit: cover;
        margin: 0 auto;
    }

    &__title {
        color: $danger;
        font-weight: 500;
        font-size: 25px;
    }

    &__text {
        padding: 1rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__info {
        color: $general-black;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
    }

    &__button {
        width: 20%;
    }
}
