.word-break {
    word-break: break-all;
}

.dropdown {
	& .dropdown-toggle {
		&.dropdown-toggle-no-caret {
			&:after {
				border: 0;
			}
		}
	}
}

.toast-success .toast-body svg,
.toast-success .toast-body span {
    color: #189771;
}

.toast-danger .toast-body svg,
.toast-danger .toast-body span {
    color: #F44336;
}

.toast-warning .toast-body svg,
.toast-warning .toast-body span {
    color: #FF9800;
}

input {
	outline: none;
}

.text-red {
	color: #F44336;
	font-weight: 600;
}

.text-green {
	color: #189771;
	font-weight: 600;
}